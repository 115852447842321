import { BolAttachments } from "./components/attachments";
import { BolFromPdf } from "./components/create-from-pdf";
import { BolForm } from "./components/form";
import { LotForm } from "./components/lot/index";
import { Bols } from "./components/table";

import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_BOL,
  PERMISSION_OBJ_LOT,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from "@/constants/permissions";

export const states = [
  {
    name: "base-layout.bols",
    url: "/bols",
    component: Bols,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_BOL, PERMISSION_READ]],
    },
  },
  {
    name: "base-layout.bols.create",
    url: "/new",
    component: BolForm,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_BOL, PERMISSION_CREATE]],
    },
  },
  {
    name: "base-layout.bols.edit",
    url: "/:bolId/edit",
    component: BolForm,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_BOL, PERMISSION_UPDATE]],
    },
  },
  {
    name: "base-layout.bols.lot",
    url: "/:bolId/lot",
    component: LotForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT, PERMISSION_CREATE],
        [PERMISSION_OBJ_BOL, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: "base-layout.bols.create-from-pdf",
    url: "/create-from-pdf",
    component: BolFromPdf,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_BOL, PERMISSION_CREATE]],
    },
  },
  {
    name: "base-layout.bols.attachments",
    url: "/:bolId/attachments",
    component: BolAttachments,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_BOL, PERMISSION_UPDATE]],
    },
  },
];
