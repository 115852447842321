import { addressStore, campaignStore, counterpartyStore } from "@/stores";
import { ProductNatureType } from "@/types/enums";
import { buildDropdownOptionsFromEnum, buildStoreOptions, productOptions } from "@/utils/common";

export const getFormFields = () => {
  return [
    {
      name: "productId",
      componentName: "dropdown",
      params: {
        i18nLabel: "bols.product",
        options: productOptions(),
      },
      rules: [{ required: true }],
    },
    {
      name: "campaignId",
      componentName: "dropdown",
      params: {
        i18nLabel: "bols.campaign",
        options: buildStoreOptions(campaignStore, "name"),
      },
      rules: [{ required: true }],
    },
    {
      name: "code",
      componentName: "input",
      params: {
        i18nLabel: "bols.code",
      },
      rules: [{ max: 50 }],
    },
    {
      name: "receiptNumber",
      componentName: "input",
      params: {
        i18nLabel: "bols.receiptNumber",
      },
      rules: [{ max: 50 }],
    },
    {
      name: "bolNumber",
      componentName: "input",
      params: {
        i18nLabel: "bols.bolNumber",
      },
      rules: [{ max: 50 }],
    },
    {
      name: "carrierId",
      componentName: "dropdown",
      params: {
        i18nLabel: "bols.carrier",
        options: buildStoreOptions(counterpartyStore, "name"),
      },
      rules: [{ required: true }],
    },
    {
      name: "carrierLocation",
      componentName: "input",
      params: {
        i18nLabel: "bols.carrierLocation",
      },
      rules: [{ max: 100 }],
    },
    {
      name: "carrierApprovalNumber",
      componentName: "input",
      params: {
        i18nLabel: "bols.carrierApprovalNumber",
      },
      rules: [{ max: 50 }],
    },
    {
      name: "carrierTelephone",
      componentName: "input",
      params: {
        i18nLabel: "bols.carrierTelephone",
      },
      rules: [{ max: 50 }],
    },
    {
      name: "destinationAddressId",
      componentName: "dropdown",
      params: {
        i18nLabel: "bols.destinationAddressId",
        options: buildStoreOptions(addressStore, "name"),
      },
      rules: [{ required: true }],
    },
    {
      name: "destinationOperatorType",
      componentName: "input",
      params: {
        i18nLabel: "bols.destinationOperatorType",
      },
      rules: [{ max: 50 }],
    },
    {
      name: "destinationCode",
      componentName: "input",
      params: {
        i18nLabel: "bols.destinationCode",
      },
      rules: [{ max: 50 }],
    },
    {
      name: "destinationTelephone",
      componentName: "input",
      params: {
        i18nLabel: "bols.destinationTelephone",
      },
      rules: [{ max: 50 }],
    },
    {
      name: "loadingDepartureLocationId",
      componentName: "dropdown",
      params: {
        i18nLabel: "bols.loadingDepartureLocationId",
        options: buildStoreOptions(addressStore, "name"),
      },
      rules: [{ required: true }],
    },
    {
      name: "loadingArrivalLocationId",
      componentName: "dropdown",
      params: {
        i18nLabel: "bols.loadingArrivalLocationId",
        options: buildStoreOptions(addressStore, "name"),
      },
      rules: [{ required: true }],
    },
    {
      name: "loadingTruckTareWeight",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.loadingTruckTareWeight",
      },
    },
    {
      name: "loadingDeclaredNumberOfBags",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.loadingDeclaredNumberOfBags",
      },
    },
    {
      name: "loadingDeclaredGrossProductWeight",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.loadingDeclaredGrossProductWeight",
      },
    },
    {
      name: "loadingDeclaredNetProductWeight",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.loadingDeclaredNetProductWeight",
      },
    },
    {
      name: "loadingTractorNumber",
      componentName: "input",
      params: {
        i18nLabel: "bols.loadingTractorNumber",
      },
      rules: [{ max: 50 }],
    },
    {
      name: "loadingTruckNumber",
      componentName: "input",
      params: {
        i18nLabel: "bols.loadingTruckNumber",
      },
      rules: [{ max: 50 }],
    },
    {
      name: "loadingCarrierId",
      componentName: "dropdown",
      params: {
        i18nLabel: "bols.loadingCarrierId",
        options: buildStoreOptions(counterpartyStore, "name"),
      },
      rules: [{ required: true }],
    },
    {
      name: "loadingDriversFullName",
      componentName: "input",
      params: {
        i18nLabel: "bols.loadingDriversFullName",
      },
      rules: [{ max: 100 }],
    },
    {
      name: "loadingAverageWeight",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.loadingAverageWeight",
        min: 0,
        max: 999999.999,
      },
    },
    {
      name: "deliveryNumberOfBagsUnloaded",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.deliveryNumberOfBagsUnloaded",
      },
    },
    {
      name: "deliveryNumberOfBagsMissing",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.deliveryNumberOfBagsMissing",
      },
    },
    {
      name: "deliveryNumberOfBagsRejected",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.deliveryNumberOfBagsRejected",
      },
    },
    {
      name: "deliveryNumberOfBagsAccepted",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.deliveryNumberOfBagsAccepted",
      },
    },
    {
      name: "deliveryDeductionRate",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.deliveryDeductionRate",
        min: 0,
      },
    },
    {
      name: "deliveryEntryWeight",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.deliveryEntryWeight",
      },
    },
    {
      name: "deliveryExitWeight",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.deliveryExitWeight",
      },
    },
    {
      name: "deliveryUnloadedProductWeight",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.deliveryUnloadedProductWeight",
      },
    },
    {
      name: "deliveryRejectedProductWeight",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.deliveryRejectedProductWeight",
      },
    },
    {
      name: "deliveryAcceptedProductWeight",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.deliveryAcceptedProductWeight",
      },
    },
    {
      name: "deliveryAfterDeductionProductWeight",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.deliveryAfterDeductionProductWeight",
      },
    },
    {
      name: "deliveryStartDate",
      componentName: "datepicker",
      params: {
        i18nLabel: "bols.deliveryStartDate",
        showTime: true,
      },
    },
    {
      name: "deliveryEndDate",
      componentName: "datepicker",
      params: {
        i18nLabel: "bols.deliveryEndDate",
        showTime: true,
      },
    },
    {
      name: "deliveryPurchasePriceKg",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.deliveryPurchasePriceKg",
        min: 0,
        max: 9999.99,
      },
    },
    {
      name: "deliveryAmountPaid",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.deliveryAmountPaid",
      },
    },
    {
      name: "deliveryPaymentMethod",
      componentName: "input",
      params: {
        i18nLabel: "bols.deliveryPaymentMethod",
      },
      rules: [{ max: 100 }],
    },
    {
      name: "deliveryIssuersBank",
      componentName: "input",
      params: {
        i18nLabel: "bols.deliveryIssuersBank",
      },
      rules: [{ max: 100 }],
    },
    {
      name: "analysisFlatBeans",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.analysisFlatBeans",
        min: 0,
      },
    },
    {
      name: "analysisWormEatenBeans",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.analysisWormEatenBeans",
        min: 0,
      },
    },
    {
      name: "analysisSproutedBeans",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.analysisSproutedBeans",
        min: 0,
      },
    },
    {
      name: "analysisSlateBeans",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.analysisSlateBeans",
        min: 0,
      },
    },
    {
      name: "analysisSplinters",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.analysisSplinters",
        min: 0,
      },
    },
    {
      name: "analysisBrokenBeans",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.analysisBrokenBeans",
        min: 0,
      },
    },
    {
      name: "analysisMoisture",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.analysisMoisture",
        min: 0,
      },
    },
    {
      name: "analysisMoldyBeans",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.analysisMoldyBeans",
        min: 0,
      },
    },
    {
      name: "analysisGraininess",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.analysisGraininess",
        min: 0,
      },
    },
    {
      name: "analysisForeignMaterials",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.analysisForeignMaterials",
        min: 0,
      },
    },
    {
      name: "analysisDefectiveBeans",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.analysisDefectiveBeans",
        min: 0,
      },
    },
    {
      name: "analysisWaste",
      componentName: "input-number",
      params: {
        i18nLabel: "bols.analysisWaste",
        min: 0,
      },
    },
    {
      name: "analysisQcSheetNumber",
      componentName: "input",
      params: {
        i18nLabel: "bols.analysisQcSheetNumber",
      },
      rules: [{ max: 50 }],
    },
    {
      name: "analysisQualityGrade",
      componentName: "input",
      params: {
        i18nLabel: "bols.analysisQualityGrade",
      },
      rules: [{ max: 50 }],
    },
    {
      name: "productNature",
      componentName: "dropdown",
      params: {
        i18nLabel: "bols.productNature",
        options: buildDropdownOptionsFromEnum(ProductNatureType),
      },
    },
  ];
};
