import {
  PERMISSION_OBJ_BV,
  PERMISSION_OBJ_LOT,
  PERMISSION_OBJ_LOT_ANALYSIS,
  PERMISSION_OBJ_LOT_FUMIGATION,
  PERMISSION_OBJ_LOT_PLEDGE,
  PERMISSION_OBJ_LOT_WEIGHING,

  PERMISSION_CREATE,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { BVForm } from './components/bv';
import { AnalysisForm } from './components/analysis';
import { AnalysisAttachments } from './components/view/analyses';
import { WeighingAttachments } from './components/view/weighings';
import { FumigationAttachments } from './components/view/fumigations';
import { PledgeAttachments } from './components/view/pledges';
import { LotAttachments } from './components/attachments';
import { LotForm } from './components/form';
import { LotMassCreateForm } from './components/form/mass-create';
import { FumigationForm } from './components/fumigation';
import { PledgeForm } from './components/pledge';
import { Lots } from './components/table';
import { LotView } from './components/view';
import { WeightForm } from './components/weight';
import { EmptyLots } from './components/empty';

export const states = [
  {
    name: 'base-layout.lots',
    url: '/lots',
    component: Lots,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.lots-empty',
    url: '/lots-empty',
    component: EmptyLots,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.lots.mass-create',
    url: '/mass-create',
    component: LotMassCreateForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.lots.edit',
    url: '/:lotId/edit',
    component: LotForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.lots.attachments',
    url: '/:lotId/attachments',
    component: LotAttachments,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.lots.view',
    url: '/:lotId/view',
    component: LotView,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.lots.weight',
    url: '/:lotId/weight/new',
    component: WeightForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT_WEIGHING, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.lots.fumigation',
    url: '/:lotId/fumigation',
    component: FumigationForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT_FUMIGATION, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.lots.mass-create-fumigation',
    url: '/mass-create-fumigation',
    component: FumigationForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT_FUMIGATION, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.lots.pledge',
    url: '/:lotId/pledge',
    component: PledgeForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT_PLEDGE, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.lots.mass-create-pledge',
    url: '/mass-create-pledges',
    component: PledgeForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT_PLEDGE, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.lots.analysis',
    url: '/:lotId/analysis',
    component: AnalysisForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT_ANALYSIS, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.lots.view.analysis-attachments',
    url: '/analysis/:analysisId/attachments',
    component: AnalysisAttachments,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT_ANALYSIS, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.lots.view.weighing-attachments',
    url: '/weighing/:weighingId/attachments',
    component: WeighingAttachments,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT_WEIGHING, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.lots.view.fumigation-attachments',
    url: '/fumigation/:fumigationId/attachments',
    component: FumigationAttachments,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT_FUMIGATION, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.lots.view.pledge-attachments',
    url: '/pledge/:pledgeId/attachments',
    component: PledgeAttachments,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_LOT_PLEDGE, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.lots.bv',
    url: '/:lotId/bv',
    component: BVForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_BV, PERMISSION_CREATE],
        [PERMISSION_OBJ_LOT, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.lots.mass-create-bv',
    url: '/mass-create-bv',
    component: BVForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_BV, PERMISSION_CREATE],
        [PERMISSION_OBJ_LOT, PERMISSION_UPDATE],
      ],
    },
  },
];
