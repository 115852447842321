import { UIViewInjectedProps } from "@uirouter/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { api } from "@/api";
import { PERMISSION_OBJ_LOT } from "@/constants/permissions";
import { getAllowedFormFields } from "@/components/generic-form/utils";
import { DrawerForm } from "@/components/drawer-form";
import { bolStore, lotStore } from "@/stores";
import { getTranslatedString } from "@/utils";

import { getFormFields } from "./setup";
import { withoutRepeatValue } from "@/utils/controller";
import { useMassUpdateCashedStores } from "@/utils/store";
import { showError } from "@/utils/common";

const entity = "bols";

export const LotForm = observer(({ transition }: UIViewInjectedProps) => {
  const { bolId } = transition.router.globals.params;
  const { loading } = useMassUpdateCashedStores([lotStore]);

  const [lotWithMaxNumber, setLotWithMaxNumber] = useState<cyrian.api.lots.Lot>();

  useEffect(() => {
    const getMaxNumber = async () => {
      try {
        const result = await api.lots.getLotWithMaxNumber().source;
        setLotWithMaxNumber(result?.data);
      } catch (error) {
        showError(error);
      }
    };

    getMaxNumber();
  }, []);

  const onClose = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const highestLotNumber = lotWithMaxNumber?.number || 'null';

  const initValues = {};
  const title = getTranslatedString("bol.lot.title", { 0: highestLotNumber });
  const formFields = getFormFields();
  const allowedFields = getAllowedFormFields(formFields, true, PERMISSION_OBJ_LOT);

  const resourceController = withoutRepeatValue(initValues, {
    create: async (values: any) => {
      const lotRequestData = {
        number: values.number,
        isDraft: true,
      };

      try {
        const result = await api.lots.create(lotRequestData).source;
        const lotId = result?.data?.id;

        console.log(`BolId: ${bolId}, LotId: ${lotId}`);
        const updateResult = await api.bols.update(bolId, { lotId }).source;
        bolStore.updateItem(updateResult?.data);
      } catch (error) {
        showError(error);
      }
    },
  });

  return (
    <DrawerForm
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={loading}
    />
  );
});
