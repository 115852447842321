import { Space } from 'antd';
import React, { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import {
  DeleteAction,
  EditAction,
} from '@/components/table/actions';
import { ProductIcon } from '@/components/icons';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_LOT,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { lotStore } from '@/stores';
import { showError } from '@/utils/common';
import { ColumnType } from 'antd/lib/table';

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_LOT, PERMISSION_UPDATE]])(EditAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_LOT, PERMISSION_DELETE]])(DeleteAction);

const Actions = (_: any, record: any) => {
  const onDelete = (e: MouseEvent) => {
    e.stopPropagation();
    api.lots.delete(record.id).source
      .then(() => lotStore.deleteItem(record.id))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction uiSref={{ to: 'base-layout.lots.edit', params: { lotId: record.id } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const columns: ColumnType<any>[] = [
  {
    dataIndex: ['product', 'name'],
    sorter: false,
    width: 50,
    render: (_, record) => <ProductIcon type={record?.product?.type} />,
  },
  {
    title: 'No.',
    dataIndex: 'number',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.number - b.number,
  },
  {
    title: <FormattedMessage id="lots.quality" />,
    dataIndex: 'quality',
    sorter: true,
  },
  {
    title: <FormattedMessage id="lots.production-status" />,
    dataIndex: 'productionStatus',
    sorter: true,
    render: (_, record) => <FormattedMessage id={`lots.production-status.${record?.productionStatus}`} />,
  },
  {
    title: "",
    render: Actions,
    className: "actions",
  },
];
