import {
  faFile,
  faFileContract,
  faFileInvoiceDollar,
  faListUl,
  faSeedling,
  faShip,
} from '@fortawesome/free-solid-svg-icons';
import {
  faPalletAlt,
  faFunnelDollar,
  faDollyFlatbedAlt,
  faClipboardCheck,
  faCommentsDollar,
  faCogs,
  faCalendarAlt,
  faBuilding,
  faMapMarker,
  faContainerStorage,
  faFileAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { BaseMenuItemProps } from '@/components/menu';
import {
  PERMISSION_OBJ_ADDRESS,
  PERMISSION_OBJ_BOARDING,
  PERMISSION_OBJ_BOL,
  PERMISSION_OBJ_BV,
  PERMISSION_OBJ_CAMPAIGN,
  PERMISSION_OBJ_CONTAINER,
  PERMISSION_OBJ_COUNTERPARTY,
  PERMISSION_OBJ_COUNTERPARTY_CONTRACT,
  PERMISSION_OBJ_CONTRACT_LINE_ITEM,
  PERMISSION_OBJ_CV,
  PERMISSION_OBJ_CV_EXECUTION,
  PERMISSION_OBJ_FORMULA,
  PERMISSION_OBJ_LOT,
  PERMISSION_OBJ_PRODUCT,
  PERMISSION_OBJ_ROLE,
  PERMISSION_OBJ_TRANSFER,
  PERMISSION_OBJ_TRANSIT_ORDER,

  PERMISSION_READ,
} from '@/constants/permissions';

export interface IMenuItem extends BaseMenuItemProps {
  key: string;
  menuType?: string;
  parent: string;
  permissions: [string, number][];
  style?: any;
}

export const menuItems: IMenuItem[] = [
  {
    key: 'bols',
    state: 'base-layout.bols',
    icon: <FontAwesomeIcon icon={faFile} />,
    messageId: 'menu.bols',
    permissions: [[PERMISSION_OBJ_BOL, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'lots-group',
    state: 'base-layout.lots',
    icon: <FontAwesomeIcon icon={faPalletAlt} />,
    messageId: 'menu.lots',
    permissions: [[PERMISSION_OBJ_LOT, PERMISSION_READ]],
    menuType: 'group',
    parent: 'root',
  },
  {
    key: 'lots-empty',
    state: 'base-layout.lots-empty',
    icon: <FontAwesomeIcon icon={faPalletAlt} />,
    messageId: 'menu.lots-empty',
    permissions: [[PERMISSION_OBJ_LOT, PERMISSION_READ]],
    parent: 'lots-group',
  },
  {
    key: 'lots',
    state: 'base-layout.lots',
    icon: <FontAwesomeIcon icon={faPalletAlt} />,
    messageId: 'menu.lots',
    permissions: [[PERMISSION_OBJ_LOT, PERMISSION_READ]],
    parent: 'lots-group',
  },
  {
    key: 'proposal',
    state: 'base-layout.proposal',
    icon: <FontAwesomeIcon icon={faFunnelDollar} />,
    messageId: 'menu.proposal',
    permissions: [[PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_READ]],
    parent: 'lots-group',
  },
  {
    key: 'boardings',
    state: 'base-layout.boardings',
    icon: <FontAwesomeIcon icon={faListUl} />,
    messageId: 'menu.boardings',
    permissions: [[PERMISSION_OBJ_BOARDING, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'transit-orders',
    state: 'base-layout.transit-orders',
    icon: <FontAwesomeIcon icon={faShip} />,
    messageId: 'menu.transit-orders',
    permissions: [[PERMISSION_OBJ_TRANSIT_ORDER, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'transfers',
    state: 'base-layout.transfers',
    icon: <FontAwesomeIcon icon={faDollyFlatbedAlt} />,
    messageId: 'menu.transfers',
    permissions: [[PERMISSION_OBJ_TRANSFER, PERMISSION_READ]],
    parent: 'lots-group',
  },
  {
    key: 'contracts',
    state: 'base-layout.counterparty-contracts',
    icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
    messageId: 'menu.counterparty-contracts',
    permissions: [[PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_READ]],
    menuType: 'group',
    parent: 'root',
  },
  {
    key: 'counterparty-contracts',
    state: 'base-layout.counterparty-contracts',
    icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
    messageId: 'menu.counterparty-contracts',
    permissions: [[PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_READ]],
    parent: 'contracts',
  },
  {
    key: 'contract-line-items',
    state: 'base-layout.contract-line-items',
    icon: <FontAwesomeIcon icon={faCommentsDollar} />,
    messageId: 'menu.contract-line-items',
    permissions: [[PERMISSION_OBJ_CONTRACT_LINE_ITEM, PERMISSION_READ]],
    parent: 'contracts',
  },
  {
    key: 'campaigns',
    state: 'base-layout.campaigns',
    icon: <FontAwesomeIcon icon={faCalendarAlt} />,
    messageId: 'menu.campaigns',
    permissions: [[PERMISSION_OBJ_CAMPAIGN, PERMISSION_READ]],
    parent: 'admin',
  },
  {
    key: 'counterparties',
    state: 'base-layout.counterparties',
    icon: <FontAwesomeIcon icon={faBuilding} />,
    messageId: 'menu.counterparties',
    permissions: [[PERMISSION_OBJ_COUNTERPARTY, PERMISSION_READ]],
    parent: 'admin',
  },
  {
    key: 'bvs',
    state: 'base-layout.bvs',
    icon: <FontAwesomeIcon icon={faClipboardCheck} />,
    messageId: 'menu.bvs',
    permissions: [[PERMISSION_OBJ_BV, PERMISSION_READ]],
    parent: 'lots-group',
  },
  {
    key: 'cvs-group',
    state: 'base-layout.cvs',
    icon: <FontAwesomeIcon icon={faFileContract} />,
    messageId: 'menu.cv-group',
    permissions: [[PERMISSION_OBJ_CV, PERMISSION_READ]],
    menuType: 'group',
    parent: 'root',
  },
  {
    key: 'cvs',
    state: 'base-layout.cvs',
    icon: <FontAwesomeIcon icon={faFileContract} />,
    messageId: 'menu.cvs',
    permissions: [[PERMISSION_OBJ_CV, PERMISSION_READ]],
    parent: 'cvs-group',
  },
  {
    key: 'cv-executions',
    state: 'base-layout.cv-executions',
    icon: <FontAwesomeIcon icon={faFileContract} />,
    messageId: 'menu.cv-executions',
    permissions: [[PERMISSION_OBJ_CV_EXECUTION, PERMISSION_READ]],
    parent: 'cvs-group',
  },
  {
    key: 'formulas',
    state: 'base-layout.formulas',
    icon: <FontAwesomeIcon icon={faFileAlt} />,
    messageId: 'menu.formulas',
    permissions: [[PERMISSION_OBJ_FORMULA, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'products',
    state: 'base-layout.products',
    icon: <FontAwesomeIcon icon={faSeedling} />,
    messageId: 'menu.products',
    permissions: [[PERMISSION_OBJ_PRODUCT, PERMISSION_READ]],
    parent: 'admin',
  },
  {
    key: 'addresses',
    state: 'base-layout.addresses',
    icon: <FontAwesomeIcon icon={faMapMarker} />,
    messageId: 'menu.addresses',
    permissions: [[PERMISSION_OBJ_ADDRESS, PERMISSION_READ]],
    parent: 'admin',
  },
  {
    key: 'containers',
    state: 'base-layout.containers',
    icon: <FontAwesomeIcon icon={faContainerStorage} />,
    messageId: 'menu.containers',
    permissions: [[PERMISSION_OBJ_CONTAINER, PERMISSION_READ]],
    parent: 'admin',
  },
  {
    key: 'admin',
    state: 'base-layout.admin',
    icon: <FontAwesomeIcon icon={faCogs} />,
    messageId: 'menu.admin',
    permissions: [[PERMISSION_OBJ_ROLE, PERMISSION_READ]],
    menuType: 'group',
    parent: 'root',
    style: { position: 'fixed', bottom: 0, color: '#000', width: '220px' },
  },
];
