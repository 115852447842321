export const PERMISSION_READ = 1;
export const PERMISSION_CREATE = 2;
export const PERMISSION_UPDATE = 4;
export const PERMISSION_DELETE = 8;

export const PERMISSION_OBJ_ADDRESS = 'address';
export const PERMISSION_OBJ_BOL = 'bol';
export const PERMISSION_OBJ_BV = 'bv';
export const PERMISSION_OBJ_CAMPAIGN = 'campaign';
export const PERMISSION_OBJ_COUNTERPARTY = 'counterparty';
export const PERMISSION_OBJ_COUNTERPARTY_CONTRACT = 'counterparty_contract';
export const PERMISSION_OBJ_CONTRACT_LINE_ITEM = 'contract_line_item';
export const PERMISSION_OBJ_CV_EXECUTION = 'cv_execution';
export const PERMISSION_OBJ_CV = 'cv';
export const PERMISSION_OBJ_LOT = 'lot';
export const PERMISSION_OBJ_LOT_ANALYSIS = 'lot_analysis';
export const PERMISSION_OBJ_LOT_FUMIGATION = 'lot_fumigation';
export const PERMISSION_OBJ_LOT_PLEDGE = 'lot_pledge';
export const PERMISSION_OBJ_LOT_WEIGHING = 'lot_weighing';
export const PERMISSION_OBJ_PROPOSAL = 'lot_proposal';
export const PERMISSION_OBJ_PRODUCT = 'product';
export const PERMISSION_OBJ_PERMISSION = 'permission';
export const PERMISSION_OBJ_PERMISSION_OBJECT = 'permission_object';
export const PERMISSION_OBJ_ROLE = 'role';
export const PERMISSION_OBJ_USER = 'user';
export const PERMISSION_OBJ_BOARDING = 'boarding';
export const PERMISSION_OBJ_TRANSIT_ORDER = 'transit_order';
export const PERMISSION_OBJ_CONTAINER = 'container';
export const PERMISSION_OBJ_TRANSFER = 'transfer';
export const PERMISSION_OBJ_FORMULA = 'formula';
export const PERMISSION_OBJ_FORMULA_PAYMENT = 'formula_payment';
export const PERMISSION_OBJ_FORMULA_TAX = 'formula_tax';
