import { UIViewInjectedProps } from "@uirouter/react";
import React from "react";

import { getTranslatedString } from "@/utils";
import { api } from "@/api";
import { Drawer } from "@/components/drawer";
import { Upload } from "@/components/upload";
import { Col, message } from "antd";
import { RcFile } from "antd/lib/upload";
import { AttachmentType } from "@/types/enums";

export const BolFromPdf = ({ transition }: UIViewInjectedProps) => {
  const request = async (fileList: RcFile[]) => {
    // only one file validation
    if (fileList.length !== 1) {
      message.error("Supports only one file");
      throw new Error("Invalid number of files");
    }

    // pdf file validation
    const invalidFiles = fileList.filter((file: RcFile) => !file.name.toLowerCase().endsWith(".pdf"));
    if (invalidFiles.length > 0) {
      message.error("You can upload only PDF files");
      throw new Error("Invalid file extension");
    }

    const formData = new FormData();
    const file: RcFile = fileList[0];
    formData.append("file", file);
    // formData.append("objectId", null);
    formData.append("type", AttachmentType.BOL);

    try {
      await api.bols.createBolFromPdf(formData).source;
      transition.router.stateService.go("base-layout.bols");
    } catch (error) {
      message.error("Something went wrong");
      throw error;
    }
  };

  const onClose = () => {
    transition.router.stateService.go("base-layout.bols");
  };

  return (
    <Drawer onClose={onClose} visible={true} title={getTranslatedString("bols.createFromPdf")}>
      <Col>
        <Upload request={request} />
      </Col>
    </Drawer>
  );
};
