import { UIViewInjectedProps } from "@uirouter/react";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

import { api } from "@/api";
import { getAllowedFormFields } from "@/components/generic-form/utils";
import { PERMISSION_OBJ_BOL } from "@/constants/permissions";
import { addressStore, bolStore, campaignStore, counterpartyStore, productStore } from "@/stores";
import { getTranslatedString } from "@/utils";
import { withoutRepeatValue } from "@/utils/controller";

import { getFormFields } from "./setup";
import { useMassUpdateCashedStores } from "@/utils/store";
import { DrawerForm } from "@/components/drawer-form";
import moment from "moment";

const entity = "bols";
const store = bolStore;

export const BolForm = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === `base-layout.${entity}.create`;
  const { bolId } = transition.router.globals.params;
  const foundItem = store.items.find(({ id }: any) => id === +bolId);

  const { loading } = useMassUpdateCashedStores([addressStore, campaignStore, productStore, counterpartyStore]);

  const onClose = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const defaultCampaignId =
    isCreate &&
    useMemo(
      () => campaignStore.items.slice().sort((a, b) => Number(b.year) - Number(a.year))[0]?.id,
      [campaignStore.items]
    );

  const initValues =
    !isCreate && foundItem
      ? {
          ...foundItem,
          productId: foundItem.product?.id,
          campaignId: foundItem.campaign?.id,
          carrierId: foundItem.carrier?.id,
          destinationAddressId: foundItem.destinationAddress?.id,
          loadingDepartureLocationId: foundItem.loadingDepartureLocation?.id,
          loadingArrivalLocationId: foundItem.loadingArrivalLocation?.id,
          loadingCarrierId: foundItem.loadingCarrier?.id,
          deliveryStartDate: foundItem.deliveryStartDate && moment(foundItem.deliveryStartDate),
          deliveryEndDate: foundItem.deliveryEndDate && moment(foundItem.deliveryEndDate),
        }
      : { campaignId: defaultCampaignId };

  const title = isCreate
    ? getTranslatedString(`${entity}.create-new`)
    : getTranslatedString(`${entity}.bol-number`, { 0: bolId });

  const formFields = getFormFields();
  const allowedFields = getAllowedFormFields(formFields, isCreate, PERMISSION_OBJ_BOL);

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: any) => api[entity].create(values).source.then(({ data }: any) => store.addItem(data)),
    update: (values: any) =>
      api[entity].update(foundItem.id, values).source.then(({ data }: any) => store.updateItem(data)),
  });

  const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };

  return (
    <DrawerForm
      {...layout}
      // objectType={NoteObjectType.Bol}
      resourceId={bolId}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={loading || (!isCreate && !foundItem)}
    />
  );
});
