export const fr = {
  'contact.header': 'Informations de contact',

  'contacts.header': 'Mes contacts',
  'contacts.create': 'Nouveau contact',
  'contacts.edit': 'Modifier le contact',

  'welcome.header': 'Page d\'accueil',
  'welcome.login-form.email': 'Email',
  'welcome.login-form.password': 'Mot de passe',

  'events.header': 'Mes événements',
  'events.create': 'Nouvel évènement',
  'events.edit': 'Modifier l\'événement',

  'home.header': 'Maison',
  'profile.header': 'Mon profil',
  'profile.logout': 'Se déconnecter',
  'settings.header': 'Mes paramètres',
  'forgot-login.header': 'Mot de passe oublié',
  'forgot-login.button.reset': 'Instructions de réinitialisation des e-mails',

  'registration.header': 'Inscription gratuite des membres',
  'registration.form.country': 'De campagne',
  'registration.form.language': 'Langue',
  'registration.button.continue': 'Continuez à configurer votre compte',
  'registration.button.sign-in': 'S\'identifier',
  'registration.text.acknowledge': 'En créant un compte, vous reconnaissez avoir lu notre politique de confidentialité et accepter nos conditions d\'utilisation.',
  'registration.text.registered': 'Vous avez déjà un compte?',

  'validation.messages.required': 'Obligatoire',
  'validation.messages.length': 'La longueur doit être comprise entre {0} et {1} symboles',
  'validation.messages.email': 'La valeur saisie n\'est pas un e-mail valide',

  'button.submit': 'Nous faire parvenir',
  'button.login': 'Connexion',
  'button.back': 'Retour',
  'button.cancel': 'Annuler',
  'button.settings': 'Réglages',
  'button.forgot-login': 'Mot de passe oublié',
  'button.registration': 'Inscription gratuite des membres',

  'menu.admin': 'Admin',
  'menu.lots-empty': 'Empty Lots',
  'menu.lots': 'Lots',
  'menu.bols': 'BOLs',
  'menu.bvs': 'BVs',
  'menu.campaigns': 'Campagnes',
  'menu.counterparties': 'Tierces parties',
  'menu.cvs': 'CVs de déblocage',
  'menu.cv-group': 'CVs',
  'menu.cv-executions': 'CVs d\'exécution',
  'menu.products': 'Produits',
  'menu.addresses': 'Carnet d\'adresses',
  'menu.counterparty-contracts': 'Contrats',
  'menu.contract-line-items': 'Fixation',
  'menu.proposal': 'Propositions',
  'menu.boardings': 'Embarquements',
  'menu.transit-orders': 'Ordres de transit',
  'menu.containers': 'Conteneurs',
  'menu.transfers': 'Transferts',
  'menu.formulas': 'Formules',

  // Common pages
  'common.access-denied.title': 'Accès refusé',
  'common.access-denied.p1': 'Vous ne disposez pas des autorisations nécessaires pour accéder à cette page.',
  'common.access-denied.p2': 'Pour plus de détails, veuillez contacter le service client.',
  'common.back-home': 'Retour à la maison',
  'common.warning': 'Attention',
  'common.search': 'Rechercher...',
  'common.note': 'Nota',

  // Table
  'table.actions': 'Comportamiento',
  'table.edit': 'Editar',
  'table.delete': 'Borrar',
  'table.report': 'Informe',
  'table.notes': 'Remarques',

  // Admin
  'admin.title': 'Page d\'administration',

  // Addresses
  'addresses.add-new': 'Ajoutez l\'adresse',
  'addresses.create-new': 'Créer une adresse',
  'addresses.address-number': 'Adresse #{0}',
  'addresses.name': 'Nom',
  'addresses.type': 'Taper',
  'addresses.country': 'De campagne',
  'addresses.city': 'Ville',
  'addresses.street': 'Rue',
  'addresses.street2': 'Rue 2',

  // Bols
  'bols.add-new': 'Ajoutez Bol',
  'bols.create-new': 'Créer une Bol',
  'bols.bol-number': 'Bol #{0}',
  'bols.product': 'Produit',
  'bols.campaign': 'Campagne',
  'bols.code': 'Code',
  'bols.receiptNumber': 'N° de bordereau',
  'bols.bolNumber': 'N° de connaissement',
  'bols.lotNumber': 'N° de Lot',

  'bols.carrier': 'Fournisseur',
  'bols.carrierLocation': 'Localisation',
  'bols.carrierApprovalNumber': 'N° d\'agrément',
  'bols.carrierTelephone': 'Téléphone',

  'bols.destinationAddressId': 'Adresse',
  'bols.destinationOperatorType': 'Type d\'opérateur',
  'bols.destinationCode': 'Code',
  'bols.destinationTelephone': 'Téléphone',

  'bols.loadingDepartureLocationId': 'Lieu de départ',
  'bols.loadingArrivalLocationId': 'Lieu d\'arrivée',
  'bols.loadingTruckTareWeight': 'Tare Camion',
  'bols.loadingDeclaredNumberOfBags': 'Nombre de sacs déclarés',
  'bols.loadingDeclaredGrossProductWeight': 'Poids produit délcaré brut ',
  'bols.loadingDeclaredNetProductWeight': 'Poids produit délcaré net',
  'bols.loadingTractorNumber': 'N° Tracteur',
  'bols.loadingTruckNumber': 'N° Camion',
  'bols.loadingCarrierId': 'Transporteur',
  'bols.loadingDriversFullName': 'Noms et prénom conducteur',
  'bols.loadingAverageWeight': 'Poids moyen',

  'bols.deliveryNumberOfBagsUnloaded': 'Nombre de sacs déchargés',
  'bols.deliveryNumberOfBagsMissing': 'Nombre de sacs manquants',
  'bols.deliveryNumberOfBagsRejected': 'Nombre de sacs refoulés',
  'bols.deliveryNumberOfBagsAccepted': 'Nombre de sacs acceptés',
  'bols.deliveryDeductionRate': 'Taux de réfaction',
  'bols.deliveryEntryWeight': 'Poids entré (brut)',
  'bols.deliveryExitWeight': 'Poids sortie (tare)',
  'bols.deliveryUnloadedProductWeight': 'Poids produit déchargé',
  'bols.deliveryRejectedProductWeight': 'Poids produit refoulé',
  'bols.deliveryAcceptedProductWeight': 'Poids produits accepté',
  'bols.deliveryAfterDeductionProductWeight': 'Poids net après refaction',
  'bols.deliveryStartDate': 'Heure entrée',
  'bols.deliveryEndDate': 'Heure sortie',
  'bols.deliveryPurchasePriceKg': 'Prix d\'achat au kg',
  'bols.deliveryAmountPaid': 'Montant payé',
  'bols.deliveryPaymentMethod': 'Mode de réglement',
  'bols.deliveryIssuersBank': 'Banque de l\'emetteur',

  'bols.analysisFlatBeans': 'Fêves plates',
  'bols.analysisWormEatenBeans': 'Fêves mitées',
  'bols.analysisSproutedBeans': 'Fêves germées',
  'bols.analysisSlateBeans': 'Fêves ardoisées',
  'bols.analysisSplinters': 'Brisures',
  'bols.analysisBrokenBeans': 'Fêves brisées',
  'bols.analysisMoisture': 'Humidités',
  'bols.analysisMoldyBeans': 'Fêves moisies',
  'bols.analysisGraininess': 'Grainage',
  'bols.analysisForeignMaterials': 'Corps étrangers',
  'bols.analysisDefectiveBeans': 'Fêves défectueuses',
  'bols.analysisWaste': 'Déchets',
  'bols.analysisQcSheetNumber': 'N° de fiche QC',
  'bols.analysisQualityGrade': 'Qualité',
  'bols.productNature': 'Nature produit',

  'bols.lot.number': 'N° de Lot',

  'bol.addLot': 'Link to new Lot',
  'bol.lot.title': 'Highest Lot Number is #{0}',

  // BVs
  'bvs.add-new': 'Ajouter BV',
  'bvs.create-new': 'Créer BV',
  'bvs.bv-number': 'BV #{0}',
  'bvs.number': 'N° de BV',
  'bvs.name': 'Nom',
  'bvs.status': 'Statut',
  'bvs.acceptance-date': 'Date D\'acceptation',
  'bvs.expiration-date': 'Date D\'expiration',
  'bvs.lots': 'Lots',
  'bvs.status.BV': 'BV',
  'bvs.status.BA': 'BA',
  'bvs.status.BV request made': 'BV demande faite',
  'bvs.status.BV to ask': 'BV à demander',
  'bvs.status.Request for extension': 'Demande d\'extension',
  'bvs.status.Rework request made': 'Demande de reprise effectuée',

  // Campaigns
  'campaigns.add-new': 'Ajouter une campagne',
  'campaigns.create-new': 'Créer une campagne',
  'campaigns.campaign-number': 'Campagne #{0}',
  'campaigns.name': 'Nom',
  'campaigns.year': 'An',

  // Countcerparties
  'counterparties.add-new': 'Ajouter une contrepartie',
  'counterparties.create-new': 'Créer une contrepartie',
  'counterparties.counterparties-number': 'Contrepartie #{0}',
  'counterparties.name': 'Nom',
  'counterparties.type': 'Taper',

  // Countcerparty Contracts
  'counterparty-contracts.add-new': 'Ajouter un contrat',
  'counterparty-contracts.create-new': 'Créer un contrat',
  'counterparty-contracts.contract-number': 'Contracter #{0}',
  'counterparty-contracts.counterparty': 'Contrepartie',
  'counterparty-contracts.client': 'Client',
  'counterparty-contracts.client-contract': 'Contrat client',
  'counterparty-contracts.shipowner-contract': 'Contrat d\'armateur',
  'counterparty-contracts.shipping-contract': 'Contrat expédition',
  'counterparty-contracts.volume': 'Volume',
  'counterparty-contracts.price': 'Prix',
  'counterparty-contracts.statistics': 'Statistiques',
  'counterparty-contracts.accepted': 'Accepté',
  'counterparty-contracts.pending': 'Proposé',
  'counterparty-contracts.received': 'A recevoir',
  'counterparty-contracts.sampling': 'Échantillonnage',
  'counterparty-contracts.proposed': 'À proposer',
  'counterparty-contracts.total': 'Total',
  'counterparty-contracts.quality': 'Qualité',
  'counterparty-contracts.grain': 'Grain',

  // Contract Line Items
  'contract-line-items.add-new': 'Nouvelle fixation',
  'contract-line-items.create-new': 'Créer une fixation de contrat',
  'contract-line-items.contract-number': 'Fixation du contrat #{0}',
  'contract-line-items.client': 'Client',
  'contract-line-items.exporter': 'Exportateur',
  'contract-line-items.final-contract': 'Contrat Final',
  'contract-line-items.number': 'Nombre',
  'contract-line-items.volume': 'Volume',
  'contract-line-items.product': 'Produit',
  'contract-line-items.execution-period': 'Période d\'exécution',
  'contract-line-items.boarding-period': 'Période d\'embarquement',
  'contract-line-items.differential': 'Différentiel',
  'contract-line-items.material-fixing-date': 'Date de fixation matière',
  'contract-line-items.currency-fixing-date': 'Date de fixation de la devise',
  'contract-line-items.fixing-price-gbp': 'Prix de fixation Gbp',
  'contract-line-items.total-fob-price': 'Prix Fob total',
  'contract-line-items.price-quality': 'Prix qualité',
  'contract-line-items.cyrian-basic-price': 'Prix de base de Cyrian',
  'contract-line-items.cyrian-discount': 'Rabais Cyrian',
  'contract-line-items.graining': 'Grainage',
  'contract-line-items.signature': 'Signature',

  // Contract Product
  'contract-product.product': 'Produit',
  'contract-product.quality': 'Qualité',
  'contract-product.gr': 'gr',
  'contract-product.volume': 'Volume',

  // CVs
  'cvs.add-new': 'Ajouter Déverrouillage CV',
  'cvs.create': 'Créer un CV',
  'cvs.cv-number': 'CV #{0}',
  'cvs.name': 'Nom',
  'cvs.number': 'Nombre',
  'cvs.volume': 'Volume',
  'cvs.price': 'Prix',
  'cvs.currency': 'Devise',
  'cvs.taxes': 'Impôts',
  'cvs.counterparty': 'Contrepartie',
  'cvs.campaign': 'Campagne',
  'cvs.period': 'Période de déblocage',
  'cvs.unlocking-date': 'Date de déblocage',
  'cvs.cv-unlocking': 'N° CV de déblocage',
  'cvs.balance': 'Équilibre',

  // CV Executions
  'cv-executions.add-new': 'Add Execution CV',
  'cv-executions.create': 'Créer une exécution de CV',
  'cv-executions.edit-number': 'Exécution de CV #{0}',
  'cv-executions.counterparty': 'Contrepartie',
  'cv-executions.cv-unlocking': 'N° de déblocage',
  'cv-executions.cv-execution': 'N° CV d\'exécution',
  'cv-executions.unlocking-period': 'Période de déblocage initiale',
  'cv-executions.execution-period': 'Période d\'exécution',
  'cv-executions.campaign': 'Campagne',
  'cv-executions.volume': 'Volume',
  'cv-executions.price': 'Prix',
  'cv-executions.number': 'Nombre',
  'cv-executions.unlocking-date': 'Date de déblocage',

  // Lots
  'lots.add-new': 'Ajouter un lot',
  'lots.mass-add-new': 'Ajouter des lots',
  'lots.mass-remove': 'Supprimer des lots',
  'lots.mass-add-pledges': 'Nantir',
  'lots.mass-add-fumigation': 'Ajouter une fumigation',
  'lots.mass-add-bv': 'Ajouter BV',
  'lots.create-new': 'Créer un lot',
  'lots.lot-number': 'Lot #{0}',
  'lots.count': 'Compter',
  'lots.unblock-cv': 'N° Cv de déblocage',
  'lots.execution-cv': 'N° CV d\'exécution',
  'lots.bv': 'BV',
  'lots.bv-expiration-date': 'Date d\'expiration du BV',
  'lots.campaign': 'Campagne',
  'lots.proposal-contract': 'Contrat de proposition',
  'lots.proposal-status': 'Statut de la proposition',
  'lots.owner': 'Propriétaire',
  'lots.final-client': 'Client final',
  'lots.product': 'Type de cacao',
  'lots.production-status': 'Statut du lot',
  'lots.fo': 'FO',
  'lots.to': 'To',
  'lots.containers': 'Conteneurs',
  'lots.number': 'Numéro de lot',
  'lots.sacks-quantity': 'Quantité de sacs',
  'lots.weight': 'Poids',
  'lots.mass-create-warn': 'Les lots #{0} existent déjà',
  'lots.mass-create-warn2': 'Certains lots existent déjà',
  'lots.factory': 'Usine',
  'lots.location': 'Emplacement',
  'lots.final-contract': 'Contrat final',
  'lots.production-date': 'Date de production',
  'lots.quality': 'Qualité',
  'lots.delete-warning-title': 'Avertissement',
  'lots.delete-warning-desc': 'Êtes-vous sûr de vouloir supprimer ces lots ?',

  'lots.production-status.To produce': 'A produire',
  'lots.production-status.Under production': 'En cours de production',
  'lots.production-status.Produced': 'Produit',

  'lots.events': 'Evénements liés au lot',
  'lots.events.id': 'Id',
  'lots.events.type': 'Taper',
  'lots.events.namespace': 'Espace de noms',
  'lots.events.comment': 'Commenter',
  'lots.events.date': 'Date',

  // Lot Analysis
  'lot.analysis': 'Analyse',
  'lot.analysis.title': 'Analysis for Lot #{0}',
  'lot.analysis.address': 'Adresse',
  'lot.bv': 'BV',
  'lot.analysis.counterparty': 'Contrepartie',
  'lot.analysis.analysis-date': 'Date d\'analyse',
  'lot.analysis.sampling-date': 'Date d\'échantillonnage',
  'lot.analysis.sampling-method': 'Méthode d\'échantillonnage',
  'lot.analysis.analysis-reference': 'Référence d\'analyse',
  'lot.analysis.sampling-reference': 'Référence d\'échantillonnage',
  'lot.analysis.classification': 'Classification',
  'lot.analysis.document-url': 'URL du document',
  'lot.analysis.total-sample-weight': 'Poids total de l\'échantillon',
  'lot.analysis.specific-weight-100g': 'Poids spécifique sur 100g',
  'lot.analysis.graining': 'Grainage',
  'lot.analysis.humidity': 'Humidité',
  'lot.analysis.screening-scrap': 'Dépistage de la ferraille',
  'lot.analysis.flat-beans': 'Haricots plats',
  'lot.analysis.crabots': 'Crabots',
  'lot.analysis.foreign-bodies': 'Corps étranger',
  'lot.analysis.broken-beans': 'Haricots cassés',
  'lot.analysis.fragments': 'Fragments',
  'lot.analysis.shell': 'Coquille',
  'lot.analysis.materials-derived': 'Matériaux dérivés',
  'lot.analysis.moldy': 'Moisi',
  'lot.analysis.moths': 'Papillons de nuit',
  'lot.analysis.slate': 'Ardoise',
  'lot.analysis.germinated': 'Germé',
  'lot.analysis.violets': 'Violettes',
  'lot.analysis.buttered': 'Beurré',

  // Lot Fumigation
  'lot.fumigation': 'Fumigation',
  'lot.fumigation.title': 'Fumigation for Lot #{0}',
  'lot.fumigation.location': 'Lieu',
  'lot.fumigation.start-date': 'Date de début',
  'lot.fumigation.end-date': 'Date de fin',

  // Lot Pledge
  'lot.pledge': 'Engagement',
  'lot.pledge.title': 'Pledge for Lot #{0}',
  'lot.pledge.counterparty': 'Contrepartie',
  'lot.pledge.request-number': 'Numéro de demande',
  'lot.pledge.ltd-number': 'ltdNumber',
  'lot.pledge.das-dae': 'dasDae',

  // Lot Weighing
  'lot.weighing': 'Pesé',
  'lot.weighing.title': 'Weighing for Lot #{0}',
  'lot.weighing.address': 'Adresse',
  'lot.weighing.counterparty': 'Contrepartie',
  'lot.weighing.date': 'Date',
  'lot.weighing.weight': 'Poids',

  // Products
  'products.add-new': 'Ajouter un produit',
  'products.create-new': 'Créer un produit',
  'products.product-number': 'Produit #{0}',
  'products.name': 'Nom',
  'products.type': 'Label',
  'products.sampling-method': 'Méthode d\'échantillonnage',
  'products.type.Ordinary': 'Ordinaire',
  'products.type.Utz': 'Utz',
  'products.type.Fair trade': 'Fair trade',
  'products.type.Rainforest alliance': 'Rainforest alliance',

  // Attachments
  'attachments.attachments': 'Fichiers',
  'attachments.title': 'Pièces jointes pour #{0}',
  'attachments.uploading': 'Téléchargement',
  'attachments.start-upload': 'Commence le téléchargement',
  'attachments.name': 'Nom',
  'attachments.created': 'Créé',
  'attachments.Size': 'Taille',

  // Proposal
  'proposal.create': 'Créer une proposition pour le lot #{0}',
  'proposal.add-many': 'Ajouter des propositions',
  'proposal.update': 'Mettre à jour la proposition #{0}',
  'proposal.new-status': 'Nouveau statut de proposition',
  'proposal.status': 'Statut',
  'proposal.update-status': 'Mettre à jour le statut de la proposition pour le lot #{0}',
  'proposal.mass-update-status': 'État de la proposition de mise à jour en masse',
  'proposal.contract-initial': 'Client contractuel initial',
  'proposal.contract-final': 'Contrat Client Final',
  'proposal.mass-sampling': 'Échantillonnage de masse',
  'proposal.mass-accepted': 'Masse acceptée',
  'proposal.mass-rejected': 'Masse rejetée',
  'proposal.customer': 'Client',
  'proposal.selected-lots': 'Lots sélectionnés',
  'proposal.code': 'Code',
  'proposal.status.withdrawn': 'Avec Drawn',
  'proposal.status.pending': 'En attente',
  'proposal.status.accepted': 'Accepté',
  'proposal.status.rejected': 'Rejeté',

  // Boardings
  'boardings.add-new': 'Ajouter un embarquement',
  'boardings.create-new': 'Créer un embarquement',
  'boardings.boarding-number': 'Embarquement #{0}',
  'boardings.contract': 'Contrat',
  'boardings.container': 'Récipient',
  'boardings.departure-address': 'Port de départ',
  'boardings.internal-client': 'Client interne',
  'boardings.final-client': 'Client final',
  'boardings.consignee': 'Destinataire',
  'boardings.final-consignee': 'Destinataire final',
  'boardings.freight-payer': 'Payeur de fret',
  'boardings.internal-client-contract': 'Contrat client interne',
  'boardings.final-client-contract': 'Contrat du client final',
  'boardings.service-contract': 'Contrat de service',
  'boardings.notify': 'Notifié',
  'boardings.sea-line': 'Ligne maritime',
  'boardings.product': 'Produit',
  'boardings.harbor-address': 'Adresse du port',
  'boardings.destination-address': 'Port de destination',
  'boardings.campaign': 'Campagne',
  'boardings.exporter': 'Exportateur',
  'boardings.inspector': 'Inspecteur',
  'boardings.shipowner': 'Armateur',
  'boardings.courier': 'Courrier',
  'boardings.container-type': 'Type de containeurs',
  'boardings.number': 'Numéro',
  'boardings.volume': 'Volume',
  'boardings.date': 'Date d’instruction d’embarquement',
  'boardings.warning': 'Avertissement',
  'boardings.warning-product-not-contract': 'Vous avez sélectionné un produit autre que le contrat indiqué',

  // Transit Orders
  'transit-orders.add-new': 'Ajouter une demande de transit',
  'transit-orders.create-new': 'Créer une demande de transit',
  'transit-orders.transit-order-number': 'Ordre de transit #{0}',
  'transit-orders.boarding': 'Embarquement',
  'transit-orders.order-number': 'N° de commande',
  'transit-orders.booking-number': 'N° de réservation',
  'transit-orders.bill-of-lading-number': 'N° de connaissement',
  'transit-orders.forwarder-file-number': 'Numéro de fichier du transitaire',
  'transit-orders.ship-name': 'Nom du Bateau',
  'transit-orders.good-to-embark': 'Bon à embarquer',
  'transit-orders.net-weight': 'Net Lester',
  'transit-orders.eta-date': 'Eta Date',
  'transit-orders.swb-date': 'Swb Date',
  'transit-orders.available-bv-volume': 'Volume Bv disponible pour la formule',
  'transit-orders.formulas-launched': 'Formules lancées',
  'transit-orders.gross-weight-diff': 'Poids Net Poids Brut Diff',
  'transit-orders.theoretical-weight': 'Poids théorique',
  'transit-orders.container': 'Récipient',
  'transit-orders.departure-address': 'Port de départ',
  'transit-orders.internal-client': 'Client interne',
  'transit-orders.final-client': 'Client final',
  'transit-orders.consignee': 'Destinataire',
  'transit-orders.final-consignee': 'Final consignee',
  'transit-orders.freight-payer': 'Payeur de fret',
  'transit-orders.internal-client-contract': 'Contrat client interne',
  'transit-orders.final-client-contract': 'Contrat du client final',
  'transit-orders.service-contract': 'Contrat de service',
  'transit-orders.freight-forwarder': 'Transitaire',
  'transit-orders.product': 'Produit',
  'transit-orders.exporter': 'Exportateur',
  'transit-orders.destination-address': 'Port de destination',

  // Containers
  'containers.add-new': 'Ajouter un conteneur',
  'containers.create-new': 'Créer un conteneur',
  'containers.container-number': 'Récipient #{0}',
  'containers.name': 'Nom',
  'containers.type': 'Taper',
  'containers.sacs-type': 'Type de sacs',
  'containers.dressing-material': 'Matériel de pansement',

  // Transfers
  'transfers.add-new': 'Ajouter Transferts',
  'transfers.create-new': 'Créer Transferts',
  'transfers.transfer-number': 'Transferts #{0}',
  'transfers.product': 'Produit',
  'transfers.campaign': 'Campagne',
  'transfers.carrier': 'Transporteur',
  'transfers.transport-charge-counterparty': 'Prise en charge du transport',
  'transfers.loading-counterparty': 'Chargement et déchargement par',
  'transfers.start-location': 'Magasin de provenance',
  'transfers.end-location': 'Magasin de destination',
  'transfers.number': 'N° de dossier',
  'transfers.status': 'Statut',
  'transfers.phyto-position': 'Position phyto',
  'transfers.phyto-treatment-days': 'Nbre de jours de traitement',
  'transfers.phone-number': 'Téléphone',
  'transfers.status.arrived': 'Arrivée',
  'transfers.status.departed': 'Commencé',
  'transfers.status.in transit': 'En transit',

  // User Settings
  'user-settings.items-per-page': 'Articles par page',
  'user-settings.dump-data': 'Télécharger les données',
  'user-settings.title': 'Paramètres',

  // Profile
  'profile.title': 'Profil',
  'profile.email': 'E-mail',
  'profile.name': 'Nom',
  'profile.role': 'Rôle',

  // Formulas
  'formulas.add-new': 'Ajouter une formule',
  'formulas.create-new': 'Créer une formule',
  'formulas.formula-number': 'Formule #{0}',
  'formulas.exporter': 'Exportateur',
  'formulas.freight-forwarder': 'Transitaire',
  'formulas.product': 'Produit',
  'formulas.campaign': 'Campagne',
  'formulas.destination': 'Destination',
  'formulas.departure-port': 'Port de départ',
  'formulas.recipient': 'Destinataire',
  'formulas.execution-cv': 'Cv d\'exécution',
  'formulas.authorization-number': 'N° d\'autorisation',
  'formulas.harvest': 'Récolter',
  'formulas.grade': 'Noter',
  'formulas.quality': 'Qualité',
  'formulas.net-weight': 'Poids net',
  'formulas.gross-weight': 'Poids brut',
  'formulas.packaging': 'Emballage',
  'formulas.packaging-count': 'Nombre d\'emballages',
  'formulas.customs-nomenclature': 'Nomenclature douanière',
  'formulas.ship': 'Bateau',
  'formulas.fO1Date': 'fO1Date',

  // Formula Payments
  'formula.payment.payments': 'Paiements',
  'formula.payment.add-new': 'Ajouter un paiement',
  'formula.payment.create-new': 'Créer un paiement',
  'formula.payment.payment-number': 'Paiement #{0}',
  'formula.payment.type': 'Taper',
  'formula.payment.weight': 'Poids',
  'formula.payment.rate': 'Taux',
  'formula.payment.support': 'Support',

  // Formula Taxes
  'formula.taxes.taxes': 'Impôts',
  'formula.taxes.add-new': 'Ajouter une taxe',
  'formula.taxes.create-new': 'Créer une taxe',
  'formula.taxes.tax-number': 'Impôt #{0}',
  'formula.taxes.bank': 'Banque',
  'formula.taxes.price': 'Prix',
  'formula.taxes.name': 'Nom',
  'formula.taxes.check': 'Vérifier',
  'formula.taxes.rate': 'Taux',
  'formula.taxes.date': 'Date',
};
