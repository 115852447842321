import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UIView, UIViewInjectedProps } from "@uirouter/react";
import { Button, Space } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { FormattedMessage } from "react-intl";

import { Table } from "@/components/table";
import { PERMISSION_CREATE, PERMISSION_OBJ_BOL } from "@/constants/permissions";
import { withPermissions } from "@/hocs/permission";
import { bolStore } from "@/stores";
import { useMassUpdateCashedStores } from "@/utils/store";

import { columns } from "./setup";

const entity = "bols";
const store = bolStore;

const CreateButton = withPermissions([[PERMISSION_OBJ_BOL, PERMISSION_CREATE]])(
  ({ onClick, i18nTitle, disabled, icon }: any) => {
    return (
      <Button onClick={onClick} type="primary" icon={icon} disabled={disabled}>
        &nbsp;&nbsp;
        <FormattedMessage id={i18nTitle} />
      </Button>
    );
  }
);

export const Bols = observer(({ transition }: UIViewInjectedProps) => {
  const items = store.items;

  useMassUpdateCashedStores([store]);

  const onAdd = () => {
    transition.router.stateService.go(`base-layout.${entity}.create`);
  };

  const onCreateFromPdf = () => {
    transition.router.stateService.go(`base-layout.${entity}.create-from-pdf`);
  };

  return (
    <>
      <Space>
        <CreateButton onClick={onAdd} i18nTitle={`${entity}.add-new`} icon={<FontAwesomeIcon icon={faPlus} />} />
        <CreateButton
          onClick={onCreateFromPdf}
          i18nTitle={`${entity}.createFromPdf`}
          icon={<FontAwesomeIcon icon={faPlus} />}
        />
      </Space>

      <Table rowKey="id" columns={columns} dataSource={[...items]} store={store} scroll={{ x: 1600 }} />
      <UIView />
    </>
  );
});
