import { UIViewInjectedProps } from "@uirouter/react";
import React from "react";

import { Attachments } from "@/components/attachments";
import { AttachmentType } from "@/types/enums";

export const BolAttachments = ({ transition }: UIViewInjectedProps) => {
  const params = {
    transition,
    apiEntity: "BOL",
    paramId: "bolId",
    attachmentType: AttachmentType.BOL,
    closeRoute: "base-layout.bols",
  };

  return <Attachments<cyrian.api.bols.Bol & { attachments: cyrian.api.attachments.Attachment[] }> {...params} />;
};
