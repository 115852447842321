import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Space } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

import { api } from "@/api";
import { AddAction, AttachmentAction, DeleteAction, EditAction } from "@/components/table/actions";
import {
  PERMISSION_CREATE,
  PERMISSION_DELETE,
  PERMISSION_OBJ_BOL,
  PERMISSION_OBJ_LOT,
  PERMISSION_UPDATE,
} from "@/constants/permissions";
import { withPermissions } from "@/hocs/permission";
import { bolStore } from "@/stores";
import { productFormattedLabel, showError } from "@/utils/common";
import { getFormattedDate } from "@/utils/moment";

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_BOL, PERMISSION_UPDATE]])(EditAction);
const PermissionedCreateLotAction = withPermissions([[PERMISSION_OBJ_LOT, PERMISSION_CREATE]])(AddAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_BOL, PERMISSION_DELETE]])(DeleteAction);
const PermissionedAttachmentAction = withPermissions([[PERMISSION_OBJ_BOL, PERMISSION_UPDATE]])(AttachmentAction);

const Actions = (_: any, record: any) => {
  const onDelete = () => {
    api.bols
      .delete(record.id)
      .source.then(() => bolStore.deleteItem(record.id))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction uiSref={{ to: "base-layout.bols.edit", params: { bolId: record.id } }} />
      <PermissionedCreateLotAction
        uiSref={{ to: "base-layout.bols.lot", params: { bolId: record.id } }}
        title="Lot"
        icon={<FontAwesomeIcon icon={faClipboardCheck} />}
        tooltipTitle="bol.addLot"
      />
      <PermissionedAttachmentAction uiSref={{ to: "base-layout.bols.attachments", params: { bolId: record.id } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const columns = [
  {
    title: "id",
    dataIndex: "id",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.product" />,
    dataIndex: ["product", "name"],
    sorter: true,
    render: (_, record) => productFormattedLabel(record.product),
  },
  {
    title: <FormattedMessage id="bols.campaign" />,
    dataIndex: ["campaign", "name"],
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.code" />,
    dataIndex: "code",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.receiptNumber" />,
    dataIndex: "receiptNumber",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.bolNumber" />,
    dataIndex: "bolNumber",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.carrier" />,
    dataIndex: ["carrier", "name"],
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.carrierLocation" />,
    dataIndex: "carrierLocation",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.carrierApprovalNumber" />,
    dataIndex: "carrierApprovalNumber",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.carrierTelephone" />,
    dataIndex: "carrierTelephone",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.destinationAddressId" />,
    dataIndex: ["destinationAddress", "name"],
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.destinationOperatorType" />,
    dataIndex: "destinationOperatorType",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.destinationCode" />,
    dataIndex: "destinationCode",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.destinationTelephone" />,
    dataIndex: "destinationTelephone",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.loadingDepartureLocationId" />,
    dataIndex: ["loadingDepartureLocation", "name"],
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.loadingArrivalLocationId" />,
    dataIndex: ["loadingArrivalLocation", "name"],
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.loadingTruckTareWeight" />,
    dataIndex: "loadingTruckTareWeight",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.loadingDeclaredNumberOfBags" />,
    dataIndex: "loadingDeclaredNumberOfBags",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.loadingDeclaredGrossProductWeight" />,
    dataIndex: "loadingDeclaredGrossProductWeight",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.loadingDeclaredNetProductWeight" />,
    dataIndex: "loadingDeclaredNetProductWeight",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.loadingTractorNumber" />,
    dataIndex: "loadingTractorNumber",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.loadingTruckNumber" />,
    dataIndex: "loadingTruckNumber",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.loadingCarrierId" />,
    dataIndex: ["loadingCarrier", "name"],
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.loadingDriversFullName" />,
    dataIndex: "loadingDriversFullName",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.loadingAverageWeight" />,
    dataIndex: "loadingAverageWeight",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.deliveryNumberOfBagsUnloaded" />,
    dataIndex: "deliveryNumberOfBagsUnloaded",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.deliveryNumberOfBagsMissing" />,
    dataIndex: "deliveryNumberOfBagsMissing",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.deliveryNumberOfBagsRejected" />,
    dataIndex: "deliveryNumberOfBagsRejected",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.deliveryNumberOfBagsAccepted" />,
    dataIndex: "deliveryNumberOfBagsAccepted",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.deliveryDeductionRate" />,
    dataIndex: "deliveryDeductionRate",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.deliveryEntryWeight" />,
    dataIndex: "deliveryEntryWeight",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.deliveryExitWeight" />,
    dataIndex: "deliveryExitWeight",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.deliveryUnloadedProductWeight" />,
    dataIndex: "deliveryUnloadedProductWeight",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.deliveryRejectedProductWeight" />,
    dataIndex: "deliveryRejectedProductWeight",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.deliveryAcceptedProductWeight" />,
    dataIndex: "deliveryAcceptedProductWeight",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.deliveryAfterDeductionProductWeight" />,
    dataIndex: "deliveryAfterDeductionProductWeight",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.deliveryStartDate" />,
    dataIndex: "deliveryStartDate",
    sorter: true,
    render: getFormattedDate,
  },
  {
    title: <FormattedMessage id="bols.deliveryEndDate" />,
    dataIndex: "deliveryEndDate",
    sorter: true,
    render: getFormattedDate,
  },
  {
    title: <FormattedMessage id="bols.deliveryPurchasePriceKg" />,
    dataIndex: "deliveryPurchasePriceKg",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.deliveryAmountPaid" />,
    dataIndex: "deliveryAmountPaid",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.deliveryPaymentMethod" />,
    dataIndex: "deliveryPaymentMethod",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.deliveryIssuersBank" />,
    dataIndex: "deliveryIssuersBank",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.analysisFlatBeans" />,
    dataIndex: "analysisFlatBeans",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.analysisWormEatenBeans" />,
    dataIndex: "analysisWormEatenBeans",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.analysisSproutedBeans" />,
    dataIndex: "analysisSproutedBeans",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.analysisSlateBeans" />,
    dataIndex: "analysisSlateBeans",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.analysisSplinters" />,
    dataIndex: "analysisSplinters",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.analysisBrokenBeans" />,
    dataIndex: "analysisBrokenBeans",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.analysisMoisture" />,
    dataIndex: "analysisMoisture",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.analysisMoldyBeans" />,
    dataIndex: "analysisMoldyBeans",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.analysisGraininess" />,
    dataIndex: "analysisGraininess",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.analysisForeignMaterials" />,
    dataIndex: "analysisForeignMaterials",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.analysisDefectiveBeans" />,
    dataIndex: "analysisDefectiveBeans",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.analysisWaste" />,
    dataIndex: "analysisWaste",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.analysisQcSheetNumber" />,
    dataIndex: "analysisQcSheetNumber",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.analysisQualityGrade" />,
    dataIndex: "analysisQualityGrade",
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.lotNumber" />,
    dataIndex: ["lot", "number"],
    sorter: true,
  },
  {
    title: <FormattedMessage id="bols.productNature" />,
    dataIndex: "productNature",
    sorter: true,
  },
  {
    title: "",
    render: Actions,
    className: "actions",
  },
];
