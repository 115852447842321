export const en = {
  'contact.header': 'Contact info',

  'contacts.header': 'My contacts',
  'contacts.create': 'New contact',
  'contacts.edit': 'Edit contact',

  'welcome.header': 'Welcome page',
  'welcome.login-form.email': 'Email',
  'welcome.login-form.password': 'Password',

  'events.header': 'My events',
  'events.create': 'New event',
  'events.edit': 'Edit event',

  'home.header': 'Home',
  'profile.header': 'My Profile',
  'profile.logout': 'Log Out',
  'settings.header': 'My settings',
  'forgot-login.header': 'Forgot Password',
  'forgot-login.button.reset': 'e-mail reset instructions',

  'registration.header': 'Free Member Registration',
  'registration.form.country': 'Country',
  'registration.form.language': 'Language',
  'registration.button.continue': 'Continue to Setup your Account',
  'registration.button.sign-in': 'Sign In',
  'registration.text.acknowledge': 'By creating an account, you acknowledge that you have read our Privacy Policy and agree to our Terms of Use.',
  'registration.text.registered': 'Already have an Account?',

  'validation.messages.required': 'Required',
  'validation.messages.length': 'Length should be {0} - {1} symbols',
  'validation.messages.email': 'Entered value is not a valid email',

  'button.submit': 'Submit',
  'button.login': 'Login',
  'button.back': 'Back',
  'button.cancel': 'Cancel',
  'button.settings': 'Settings',
  'button.forgot-login': 'Forgot Password',
  'button.registration': 'Free Member Registration',

  'menu.admin': 'Admin',
  'menu.lots-empty': 'Empty Lots',
  'menu.lots': 'Lots',
  'menu.bols': 'BOLs',
  'menu.bvs': 'BVs',
  'menu.campaigns': 'Campaigns',
  'menu.counterparties': 'Company Book',
  'menu.cvs': 'CVs',
  'menu.cv-group': 'CVs',
  'menu.cv-executions': 'CV Execution',
  'menu.products': 'Products',
  'menu.addresses': 'Address Book',
  'menu.counterparty-contracts': 'Contracts',
  'menu.contract-line-items': 'Fixings',
  'menu.proposal': 'Proposal',
  'menu.boardings': 'Boardings',
  'menu.transit-orders': 'Transit Orders',
  'menu.containers': 'Containers',
  'menu.transfers': 'Transfers',
  'menu.formulas': 'Formulas',

  // Common pages
  'common.access-denied.title': 'Access Denied',
  'common.access-denied.p1': 'You don\'t have permissions to access this page.',
  'common.access-denied.p2': 'For more details, please contact customer support.',
  'common.back-home': 'Back Home',
  'common.warning': 'Warning',
  'common.search': 'Search...',
  'common.note': 'Note',

  // Table
  'table.actions': 'Actions',
  'table.edit': 'Edit',
  'table.delete': 'Delete',
  'table.report': 'Report',
  'table.notes': 'Notes',

  // Admin
  'admin.title': 'Admin page',

  // Addresses
  'addresses.add-new': 'Add Address',
  'addresses.create-new': 'Create Address',
  'addresses.address-number': 'Address #{0}',
  'addresses.name': 'Name',
  'addresses.type': 'Type',
  'addresses.country': 'Country',
  'addresses.city': 'City',
  'addresses.street': 'Street',
  'addresses.street2': 'Street 2',

  // Bols
  'bols.add-new': 'Add Bol',
  'bols.create-new': 'Create Bol',
  'bols.bol-number': 'Bol #{0}',
  'bols.product': 'Product',
  'bols.campaign': 'Campaign',
  'bols.code': 'Code',
  'bols.receiptNumber': 'Receipt Number',
  'bols.bolNumber': 'BOL Number',
  'bols.lotNumber': 'Lot Number',
  'bols.createFromPdf': 'Create from PDF',

  'bols.carrier': 'Carrier',
  'bols.carrierLocation': 'Carrier Location',
  'bols.carrierApprovalNumber': 'Carrier Approval Number',
  'bols.carrierTelephone': 'Carrier Telephone',

  'bols.destinationAddressId': 'Destination Address',
  'bols.destinationOperatorType': 'Destination Operator Type',
  'bols.destinationCode': 'Destination Code',
  'bols.destinationTelephone': 'Destination Telephone',

  'bols.loadingDepartureLocationId': 'Departure Location',
  'bols.loadingArrivalLocationId': 'Arrival Location',
  'bols.loadingTruckTareWeight': 'Truck Tare Weight',
  'bols.loadingDeclaredNumberOfBags': 'Declared Number Of Bags',
  'bols.loadingDeclaredGrossProductWeight': 'Declared Gross Product Weight',
  'bols.loadingDeclaredNetProductWeight': 'Declared Net Product Weight',
  'bols.loadingTractorNumber': 'Tractor Number',
  'bols.loadingTruckNumber': 'Truck Number',
  'bols.loadingCarrierId': 'Carrier',
  'bols.loadingDriversFullName': 'Drivers Full Name',
  'bols.loadingAverageWeight': 'Loading Average Weight',

  'bols.deliveryNumberOfBagsUnloaded': 'Number Of Bags Unloaded',
  'bols.deliveryNumberOfBagsMissing': 'Number Of Bags Missing',
  'bols.deliveryNumberOfBagsRejected': 'Number Of Bags Rejected',
  'bols.deliveryNumberOfBagsAccepted': 'Number Of Bags Accepted',
  'bols.deliveryDeductionRate': 'Deduction Rate',
  'bols.deliveryEntryWeight': 'Entry Weight',
  'bols.deliveryExitWeight': 'Exit Weight',
  'bols.deliveryUnloadedProductWeight': 'Unloaded Product Weight',
  'bols.deliveryRejectedProductWeight': 'Rejected Product Weight',
  'bols.deliveryAcceptedProductWeight': 'Accepted Product Weight',
  'bols.deliveryAfterDeductionProductWeight': 'After Deduction Product Weight',
  'bols.deliveryStartDate': 'Entry Time',
  'bols.deliveryEndDate': 'Exit Time',
  'bols.deliveryPurchasePriceKg': 'Purchase Price Kg',
  'bols.deliveryAmountPaid': 'Amount Paid',
  'bols.deliveryPaymentMethod': 'Payment Method',
  'bols.deliveryIssuersBank': 'Issuer\'s Bank',

  'bols.analysisFlatBeans': 'Flat Beans',
  'bols.analysisWormEatenBeans': 'Worm Eaten Beans',
  'bols.analysisSproutedBeans': 'Sprouted Beans',
  'bols.analysisSlateBeans': 'Slate Beans',
  'bols.analysisSplinters': 'Splinters',
  'bols.analysisBrokenBeans': 'Broken Beans',
  'bols.analysisMoisture': 'Moisture',
  'bols.analysisMoldyBeans': 'Moldy Beans',
  'bols.analysisGraininess': 'Graininess',
  'bols.analysisForeignMaterials': 'Foreign Materials',
  'bols.analysisDefectiveBeans': 'Defective Beans',
  'bols.analysisWaste': 'Waste',
  'bols.analysisQcSheetNumber': 'QC Sheet Number',
  'bols.analysisQualityGrade': 'Quality Grade',
  'bols.productNature': 'Product Nature',

  'bols.lot.number': 'Lot Number',

  'bol.addLot': 'Link to new Lot',
  'bol.lot.title': 'Highest Lot Number is #{0}',

  // BVs
  'bvs.add-new': 'Add BV',
  'bvs.create-new': 'Create BV',
  'bvs.bv-number': 'BV #{0}',
  'bvs.number': 'Number',
  'bvs.name': 'Name',
  'bvs.status': 'Status',
  'bvs.acceptance-date': 'Acceptance Date',
  'bvs.expiration-date': 'Expiration Date',
  'bvs.lots': 'Lots',
  'bvs.status.BV': 'BV',
  'bvs.status.BA': 'BA',
  'bvs.status.BV request made': 'BV request made',
  'bvs.status.BV to ask': 'BV to ask',
  'bvs.status.Request for extension': 'Request for extension',
  'bvs.status.Rework request made': 'Rework request made',

  // Campaigns
  'campaigns.add-new': 'Add Campaign',
  'campaigns.create-new': 'Create Campaign',
  'campaigns.campaign-number': 'Campaign #{0}',
  'campaigns.name': 'Name',
  'campaigns.year': 'Year',

  // Counterparties
  'counterparties.add-new': 'Company',
  'counterparties.create-new': 'Create Company',
  'counterparties.counterparties-number': 'Company #{0}',
  'counterparties.name': 'Name',
  'counterparties.type': 'Type',

  // Countcerparty Contracts
  'counterparty-contracts.add-new': 'Add Contract',
  'counterparty-contracts.create-new': 'Create Contract',
  'counterparty-contracts.contract-number': 'Contract #{0}',
  'counterparty-contracts.counterparty': 'Counterparty',
  'counterparty-contracts.client': 'Client',
  'counterparty-contracts.client-contract': 'Client Contract',
  'counterparty-contracts.shipowner-contract': 'Shipowner Contract',
  'counterparty-contracts.shipping-contract': 'Shipping Contract',
  'counterparty-contracts.volume': 'Volume',
  'counterparty-contracts.price': 'Price',
  'counterparty-contracts.statistics': 'Statistics',
  'counterparty-contracts.accepted': 'Accepted',
  'counterparty-contracts.pending': 'Proposed',
  'counterparty-contracts.received': 'Received',
  'counterparty-contracts.sampling': 'Sampling',
  'counterparty-contracts.proposed': 'To Be Proposed',
  'counterparty-contracts.total': 'Total',
  'counterparty-contracts.quality': 'Quality',
  'counterparty-contracts.grain': 'Grain',

  // Contract Line Items
  'contract-line-items.add-new': 'Add Contract Fixing',
  'contract-line-items.create-new': 'Create Contract Fixing',
  'contract-line-items.line-item-number': 'Contract Fixing #{0}',
  'contract-line-items.client': 'Client',
  'contract-line-items.exporter': 'Exporter',
  'contract-line-items.final-contract': 'Final Contract',
  'contract-line-items.number': 'Number',
  'contract-line-items.volume': 'Volume',
  'contract-line-items.product': 'Product',
  'contract-line-items.execution-period': 'Execution Period',
  'contract-line-items.boarding-period': 'Boarding Period',
  'contract-line-items.differential': 'Differential',
  'contract-line-items.material-fixing-date': 'Material Fixing Date',
  'contract-line-items.currency-fixing-date': 'Currency Fixing Date',
  'contract-line-items.fixing-price-gbp': 'Fixing Price Gbp',
  'contract-line-items.total-fob-price': 'Total Fob Price',
  'contract-line-items.price-quality': 'Price Quality',
  'contract-line-items.cyrian-basic-price': 'Cyrian Basic Price',
  'contract-line-items.cyrian-discount': 'Cyrian Discount',
  'contract-line-items.graining': 'Graining',
  'contract-line-items.signature': 'Signature',

  // Contract Product
  'contract-product.product': 'Product',
  'contract-product.quality': 'Quality',
  'contract-product.gr': 'gr',
  'contract-product.volume': 'Volume',

  // CVs
  'cvs.add-new': 'Add Unlocking CV',
  'cvs.create': 'Create Unlocking CV',
  'cvs.cv-number': 'CV #{0}',
  'cvs.name': 'Name',
  'cvs.number': 'Number',
  'cvs.volume': 'Volume',
  'cvs.price': 'Price',
  'cvs.currency': 'Currency',
  'cvs.taxes': 'Taxes',
  'cvs.counterparty': 'Counterparty',
  'cvs.campaign': 'Campaign',
  'cvs.period': 'Unlocking Period',
  'cvs.unlocking-date': 'Unlocking Date',
  'cvs.cv-unlocking': 'CV Unlocking',
  'cvs.balance': 'Balance',

  // CV Executions
  'cv-executions.add-new': 'Add Execution CV',
  'cv-executions.create': 'Create CV Execution',
  'cv-executions.edit-number': 'CV Execution #{0}',
  'cv-executions.counterparty': 'Counterparty',
  'cv-executions.cv-unlocking': 'CV Unlocking',
  'cv-executions.cv-execution': 'CV Execution',
  'cv-executions.unlocking-period': 'Unlocking Period',
  'cv-executions.execution-period': 'Execution Period',
  'cv-executions.campaign': 'Campaign',
  'cv-executions.volume': 'Volume',
  'cv-executions.price': 'Price',
  'cv-executions.number': 'Number',
  'cv-executions.unlocking-date': 'Unlocking Date',

  // Lots
  'lots.add-new': 'Add Lot',
  'lots.mass-add-new': 'Add Lots',
  'lots.mass-remove': 'Delete Lots',
  'lots.mass-add-pledges': 'Add Pledges',
  'lots.mass-add-fumigation': 'Add Fumigation',
  'lots.mass-add-bv': 'Add BV',
  'lots.create-new': 'Create Lot',
  'lots.lot-number': 'Lot #{0}',
  'lots.count': 'Count',
  'lots.unblock-cv': 'Unblock Cv',
  'lots.execution-cv': 'Execution Cv',
  'lots.bv': 'BV',
  'lots.bv-expiration-date': 'BV Expiration Date',
  'lots.campaign': 'Campaign',
  'lots.proposal-contract': 'Proposal Contract',
  'lots.proposal-status': 'Proposal Status',
  'lots.owner': 'Owner',
  'lots.final-client': 'Final Client',
  'lots.product': 'Type of cacao',
  'lots.production-status': 'Lot status',
  'lots.fo': 'FO',
  'lots.to': 'To',
  'lots.containers': 'Containers',
  'lots.number': 'Lot Number',
  'lots.sacks-quantity': 'Number of bags',
  'lots.weight': 'Weight',
  'lots.mass-create-warn': 'Lots #{0} already exist',
  'lots.mass-create-warn2': 'Some lots already exist',
  'lots.factory': 'Factory',
  'lots.location': 'Location',
  'lots.final-contract': 'Final Contract',
  'lots.production-date': 'Production Date',
  'lots.quality': 'Quality',
  'lots.delete-warning-title': 'Warning',
  'lots.delete-warning-desc': 'Are you sure you want to delete these lots?',

  'lots.production-status.To produce': 'To produce',
  'lots.production-status.Under production': 'Under production',
  'lots.production-status.Produced': 'Produced',

  'lots.events': 'Lot Events',
  'lots.events.id': 'Id',
  'lots.events.type': 'Type',
  'lots.events.namespace': 'Namespace',
  'lots.events.comment': 'Comment',
  'lots.events.date': 'Date',

  // Lot Analysis
  'lot.analysis': 'Analysis',
  'lot.analysis.title': 'Analysis for Lot #{0}',
  'lot.analysis.address': 'Address',
  'lot.bv': 'BV',
  'lot.analysis.counterparty': 'Counterparty',
  'lot.analysis.analysis-date': 'Analysis Date',
  'lot.analysis.sampling-date': 'Sampling Date',
  'lot.analysis.sampling-method': 'Sampling Method',
  'lot.analysis.analysis-reference': 'Analysis Reference',
  'lot.analysis.sampling-reference': 'Sampling Reference',
  'lot.analysis.classification': 'Classification',
  'lot.analysis.document-url': 'Document Url',
  'lot.analysis.total-sample-weight': 'TotalSample Weight',
  'lot.analysis.specific-weight-100g': 'Specific Weight On 100g',
  'lot.analysis.graining': 'Graining',
  'lot.analysis.humidity': 'Humidity',
  'lot.analysis.screening-scrap': 'Screening Scrap',
  'lot.analysis.flat-beans': 'Flat Beans',
  'lot.analysis.crabots': 'Crabots',
  'lot.analysis.foreign-bodies': 'Foreign Bodies',
  'lot.analysis.broken-beans': 'Broken Beans',
  'lot.analysis.fragments': 'Fragments',
  'lot.analysis.shell': 'Shell',
  'lot.analysis.materials-derived': 'Materials Derived',
  'lot.analysis.moldy': 'Moldy',
  'lot.analysis.moths': 'Moths',
  'lot.analysis.slate': 'Slate',
  'lot.analysis.germinated': 'Germinated',
  'lot.analysis.violets': 'Violets',
  'lot.analysis.buttered': 'Buttered',

  // Lot Fumigation
  'lot.fumigation': 'Fumigation',
  'lot.fumigation.title': 'Fumigation for Lot #{0}',
  'lot.fumigation.location': 'Location',
  'lot.fumigation.start-date': 'Start Date',
  'lot.fumigation.end-date': 'End Date',

  // Lot Pledge
  'lot.pledge': 'Pledge',
  'lot.pledge.title': 'Pledge for Lot #{0}',
  'lot.pledge.counterparty': 'Counterparty',
  'lot.pledge.request-number': 'Request Number',
  'lot.pledge.ltd-number': 'ltdNumber',
  'lot.pledge.das-dae': 'dasDae',

  // Lot Weighing
  'lot.weighing': 'Weighing',
  'lot.weighing.title': 'Weighing for Lot #{0}',
  'lot.weighing.address': 'Address',
  'lot.weighing.counterparty': 'Counterparty',
  'lot.weighing.date': 'Date',
  'lot.weighing.weight': 'Weight',

  // Products
  'products.add-new': 'Add Product',
  'products.create-new': 'Create Product',
  'products.product-number': 'Product #{0}',
  'products.name': 'Name',
  'products.type': 'Type',
  'products.sampling-method': 'Sampling Method',
  'products.type.Ordinary': 'Ordinary',
  'products.type.Utz': 'Utz',
  'products.type.Fair trade': 'Fair trade',
  'products.type.Rainforest alliance': 'Rainforest alliance',

  // Attachments
  'attachments.attachments': 'Attachments',
  'attachments.title': 'Attachments for #{0}',
  'attachments.uploading': 'Uploading',
  'attachments.start-upload': 'Start Upload',
  'attachments.name': 'Name',
  'attachments.created': 'Created',
  'attachments.Size': 'Size',

  // Proposal
  'proposal.create': 'Create Proposal for Lot #{0}',
  'proposal.add-many': 'Add Proposals',
  'proposal.update': 'Update Proposal #{0}',
  'proposal.new-status': 'New Proposal Status',
  'proposal.status': 'Status',
  'proposal.update-status': 'Update Proposal Status for Lot #{0}',
  'proposal.mass-update-status': 'Mass Update Proposal Status',
  'proposal.contract-initial': 'Contract Client Initial',
  'proposal.contract-final': 'Contract Client Final',
  'proposal.mass-sampling': 'Mass Sampling',
  'proposal.mass-accepted': 'Mass Accepted',
  'proposal.mass-rejected': 'Mass Rejected',
  'proposal.customer': 'Client',
  'proposal.selected-lots': 'Selected Lots',
  'proposal.code': 'Code',
  'proposal.status.withdrawn': 'With Drawn',
  'proposal.status.pending': 'Pending',
  'proposal.status.accepted': 'Accepted',
  'proposal.status.rejected': 'Rejected',

  // Boardings
  'boardings.add-new': 'Add Boarding',
  'boardings.create-new': 'Create Boarding',
  'boardings.boarding-number': 'Boarding #{0}',
  'boardings.contract': 'Contract',
  'boardings.container': 'Container',
  'boardings.departure-address': 'Departure Port',
  'boardings.internal-client': 'Internal client',
  'boardings.final-client': 'Final client',
  'boardings.consignee': 'Consignee',
  'boardings.final-consignee': 'Final consignee',
  'boardings.freight-payer': 'Freight payer',
  'boardings.internal-client-contract': 'Contract for internal client',
  'boardings.final-client-contract': 'Contract for final client',
  'boardings.service-contract': 'Contract of service',
  'boardings.notify': 'Notify',
  'boardings.sea-line': 'Sea line',
  'boardings.product': 'Product',
  'boardings.harbor-address': 'Harbor Address',
  'boardings.destination-address': 'Destination Port',
  'boardings.campaign': 'Campaign',
  'boardings.exporter': 'Exporter',
  'boardings.inspector': 'Inspector',
  'boardings.shipowner': 'Shipowner',
  'boardings.courier': 'Courier',
  'boardings.container-type': 'Type of containers',
  'boardings.number': 'Number',
  'boardings.volume': 'Volume',
  'boardings.date': 'Date of instruction',
  'boardings.warning': 'Warning',
  'boardings.warning-product-not-contract': 'You selected product other than the contract stated',

  // Transit Orders
  'transit-orders.add-new': 'Add Transit Order',
  'transit-orders.create-new': 'Create Transit Order',
  'transit-orders.transit-order-number': 'Transit Order #{0}',
  'transit-orders.boarding': 'Boarding',
  'transit-orders.order-number': 'Order Number',
  'transit-orders.booking-number': 'Booking Number',
  'transit-orders.bill-of-lading-number': 'Bill Of Lading Number',
  'transit-orders.forwarder-file-number': 'Forwarder File Number',
  'transit-orders.ship-name': 'Ship Name',
  'transit-orders.good-to-embark': 'Good To Embark',
  'transit-orders.net-weight': 'Net Weight',
  'transit-orders.eta-date': 'Eta Date',
  'transit-orders.swb-date': 'Swb Date',
  'transit-orders.available-bv-volume': 'Available Bv Volume For Formula',
  'transit-orders.formulas-launched': 'Formulas Launched',
  'transit-orders.gross-weight-diff': 'Net Weight Gross Weight Diff',
  'transit-orders.theoretical-weight': 'Theoretical Weight',
  'transit-orders.container': 'Container',
  'transit-orders.departure-address': 'Departure Port',
  'transit-orders.internal-client': 'Internal client',
  'transit-orders.final-client': 'Final client',
  'transit-orders.consignee': 'Consignee',
  'transit-orders.final-consignee': 'Final consignee',
  'transit-orders.freight-payer': 'Freight payer',
  'transit-orders.internal-client-contract': 'Contract for internal client',
  'transit-orders.final-client-contract': 'Contract for final client',
  'transit-orders.service-contract': 'Contract of service',
  'transit-orders.freight-forwarder': 'Freight Forwarder',
  'transit-orders.product': 'Product',
  'transit-orders.exporter': 'Exporter',
  'transit-orders.destination-address': 'Destination Port',

  // Containers
  'containers.add-new': 'Add Container',
  'containers.create-new': 'Create Container',
  'containers.container-number': 'Container #{0}',
  'containers.name': 'Name',
  'containers.type': 'Type',
  'containers.sacs-type': 'Sacs Type',
  'containers.dressing-material': 'Dressing Material',

  // Transfers
  'transfers.add-new': 'Add Transfer',
  'transfers.create-new': 'Create Transfer',
  'transfers.transfer-number': 'Transfer #{0}',
  'transfers.product': 'Product',
  'transfers.campaign': 'Campaign',
  'transfers.carrier': 'Carrier',
  'transfers.transport-charge-counterparty': 'Transport Charge Counterparty',
  'transfers.loading-counterparty': 'Loading Counterparty',
  'transfers.start-location': 'Start Location',
  'transfers.end-location': 'End Location',
  'transfers.number': 'Number',
  'transfers.status': 'Status',
  'transfers.phyto-position': 'Phyto Position',
  'transfers.phyto-treatment-days': 'Phyto Treatment Days',
  'transfers.phone-number': 'Phone Number',
  'transfers.status.arrived': 'Arrived',
  'transfers.status.departed': 'Departed',
  'transfers.status.in transit': 'In transit',

  // User Settings
  'user-settings.items-per-page': 'Items by page',
  'user-settings.dump-data': 'Dump data',
  'user-settings.title': 'Settings',

  // Profile
  'profile.title': 'Profile',
  'profile.email': 'Email',
  'profile.name': 'Name',
  'profile.role': 'Role',

  // Formulas
  'formulas.add-new': 'Add Formula',
  'formulas.create-new': 'Create Formula',
  'formulas.formula-number': 'Formula #{0}',
  'formulas.exporter': 'Exporter',
  'formulas.freight-forwarder': 'Freight Forwarder',
  'formulas.product': 'Product',
  'formulas.campaign': 'Campaign',
  'formulas.destination': 'Destination',
  'formulas.departure-port': 'Departure Port',
  'formulas.recipient': 'Recipient',
  'formulas.execution-cv': 'Execution Cv',
  'formulas.authorization-number': 'Authorization Number',
  'formulas.harvest': 'Harvest',
  'formulas.grade': 'Grade',
  'formulas.quality': 'Quality',
  'formulas.net-weight': 'Net Weight',
  'formulas.gross-weight': 'Gross Weight',
  'formulas.packaging': 'Packaging',
  'formulas.packaging-count': 'Packaging Count',
  'formulas.customs-nomenclature': 'Customs Nomenclature',
  'formulas.ship': 'Ship',
  'formulas.fO1Date': 'fO1Date',

  // Formula Payments
  'formula.payment.payments': 'Payments',
  'formula.payment.add-new': 'Add Payment',
  'formula.payment.create-new': 'Create Payment',
  'formula.payment.payment-number': 'Payment #{0}',
  'formula.payment.type': 'Type',
  'formula.payment.weight': 'Weight',
  'formula.payment.rate': 'Rate',
  'formula.payment.support': 'Support',

  // Formula Taxes
  'formula.taxes.taxes': 'Taxes',
  'formula.taxes.add-new': 'Add Tax',
  'formula.taxes.create-new': 'Create Tax',
  'formula.taxes.tax-number': 'Tax #{0}',
  'formula.taxes.bank': 'Bank',
  'formula.taxes.price': 'Price',
  'formula.taxes.name': 'Name',
  'formula.taxes.check': 'Check',
  'formula.taxes.rate': 'Rate',
  'formula.taxes.date': 'Date',
};
