import { request } from "@/utils/request";
import { ApiResponse, WrappedResult } from "./types";

export default {
  getAll(params?: cyrian.api.bols.GetParams): ApiResponse<WrappedResult<cyrian.api.bols.Bol[]>> {
    return request({
      method: "get",
      url: "/bols",
      params,
    });
  },
  get(id: number): ApiResponse<WrappedResult<cyrian.api.bols.Bol>> {
    return request({
      method: "get",
      url: `/bols/${id}`,
    });
  },
  create(data: cyrian.api.bols.Params): ApiResponse<WrappedResult<cyrian.api.bols.Bol>> {
    return request({
      method: "post",
      url: "/bols",
      data,
    });
  },
  update(id: number, data: cyrian.api.bols.Params): ApiResponse<WrappedResult<cyrian.api.bols.Bol>> {
    return request({
      method: "patch",
      url: `/bols/${id}`,
      data,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: "delete",
      url: `/bols/${id}`,
    });
  },
  // TODO add data type
  createBolFromPdf(data: any): ApiResponse<WrappedResult<any>> {
    return request({
      method: "post",
      url: "/bols/create-from-pdf",
      headers: {
        Accept: "*/*",
        "Content-Type": "multipart/form-data",
      },
      timeout: 90000,
      data,
    });
  },
};
